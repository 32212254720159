import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { withAdminAuth } from '../../../contexts/adminAuthContext';
import { getUserDetails, getUserJobs, enableUser, disableUser, deleteJob, getJobCandidateCount } from '../../../services/adminService';
import { handleError } from '../../../utils/errorHandler';
import { AnimatedPage } from '../../../utils/animations';
import ErrorAlert from '../../common/ErrorAlert';
import LoadingSpinner from '../../common/LoadingSpinner';
import { User, Mail, Calendar, Building, Briefcase, Users, Globe, Link as LinkIcon, Shield, CheckCircle, Clock, Trash2, ChevronLeft, ChevronRight, Eye, ArrowLeft, Loader2, Send, Activity } from 'lucide-react';
import { PAGINATION } from '../../../constants/appConstants';
import { useToast } from "@/components/ui/use-toast";
import { generateCandidateConfirmationEmail } from '../../../templates/candidateConfirmationEmail';
import AdminJobDetails from '../jobs/AdminJobDetails';

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

const AdminUserDetails = () => {
    const [user, setUser] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [jobDeleteModalOpen, setJobDeleteModalOpen] = useState(false);
    const [jobToDelete, setJobToDelete] = useState(null);
    const [bulkDeleteModalOpen, setBulkDeleteModalOpen] = useState(false);
    const [selectedJobs, setSelectedJobs] = useState(new Set());
    const [emailPreviewOpen, setEmailPreviewOpen] = useState(false);
    const [emailPreviewHtml, setEmailPreviewHtml] = useState('');
    const { id } = useParams();
    const navigate = useNavigate();
    const { toast } = useToast();

    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(PAGINATION.ITEMS_PER_PAGE);
    const [disablingUser, setDisablingUser] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [jobDetailsOpen, setJobDetailsOpen] = useState(false);
    const [selectedJobId, setSelectedJobId] = useState(null);

    useEffect(() => {
        fetchUserDetails();
    }, [id]);

    const fetchUserDetails = async () => {
        try {
            const userData = await getUserDetails(id);
            setUser(userData);
            const userJobs = await getUserJobs(id);
            const jobsWithCandidateCount = await Promise.all(
                userJobs.map(async (job) => {
                    const candidateCount = await getJobCandidateCount(job.id);
                    return { ...job, candidateCount };
                })
            );
            const sortedJobs = jobsWithCandidateCount.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
            setJobs(sortedJobs);
            setLoading(false);
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
            setLoading(false);
        }
    };

    const handlePreviewEmail = () => {
        const emailSettings = user.emailSettings || {
            confirmationSubject: 'Application Received',
            confirmationBody: '<p>Thank you for your application. We have received it and will review it shortly.</p>'
        };

        const previewHtml = generateCandidateConfirmationEmail(
            '{candidateName}',
            user.companyName || '{companyName}',
            emailSettings
        );

        setEmailPreviewHtml(previewHtml);
        setEmailPreviewOpen(true);
    };

    const handleToggleUserStatus = async () => {
        setDisablingUser(true);
        try {
            if (user.disabled) {
                await enableUser(id);
                toast({
                    title: "User Enabled",
                    description: `${user.firstName} ${user.lastName} has been enabled successfully.`,
                });
            } else {
                await disableUser(id);
                toast({
                    title: "User Disabled",
                    description: `${user.firstName} ${user.lastName} has been disabled successfully.`,
                });
            }
            fetchUserDetails();
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
            toast({
                title: "Error",
                description: `Failed to ${user.disabled ? 'enable' : 'disable'} user. ${message}`,
                variant: "destructive",
            });
        } finally {
            setDisablingUser(false);
        }
    };

    const handleDeleteClick = (job) => {
        setJobToDelete(job);
        setJobDeleteModalOpen(true);
    };

    const handleJobDelete = async () => {
        setIsDeleting(true);
        try {
            await deleteJob(jobToDelete.id);
            fetchUserDetails();
            setJobDeleteModalOpen(false);
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        } finally {
            setIsDeleting(false);
        }
    };

    const handleBulkDelete = async () => {
        setIsDeleting(true);
        try {
            await Promise.all(Array.from(selectedJobs).map(jobId => deleteJob(jobId)));
            setSelectedJobs(new Set());
            fetchUserDetails();
            setBulkDeleteModalOpen(false);
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        } finally {
            setIsDeleting(false);
        }
    };

    const toggleJobSelection = (jobId) => {
        setSelectedJobs(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(jobId)) {
                newSelected.delete(jobId);
            } else {
                newSelected.add(jobId);
            }
            return newSelected;
        });
    };

    const handleViewJobDetails = (jobId) => {
        setSelectedJobId(jobId);
        setJobDetailsOpen(true);
    };

    const columns = [
        { name: "", id: "select" },
        { name: "TITLE", id: "title" },
        { name: "COMPANY", id: "company" },
        { name: "LOCATION", id: "location" },
        { name: "PUBLISHED DATE", id: "publishedDate" },
        { name: "STATUS", id: "status" },
        { name: "CANDIDATES", id: "candidateCount" },
        { name: "ACTIONS", id: "actions" }
    ];

    const renderCell = (job, columnId) => {
        switch (columnId) {
            case 'select':
                return (
                    <Checkbox
                        checked={selectedJobs.has(job.id)}
                        onCheckedChange={(checked) => toggleJobSelection(job.id)}
                    />
                );
            case 'title':
                return job.title;
            case 'company':
                return job.company;
            case 'location':
                return `${job.city}, ${job.country}`;
            case 'publishedDate':
                return new Date(job.createdAt.toDate()).toLocaleDateString();
            case 'status':
                return (
                    <Badge variant={job.status === 'Active' ? "success" : "secondary"}>
                        {job.status}
                    </Badge>
                );
            case 'candidateCount':
                return job.candidateCount || 0;
            case 'actions':
                return (
                    <div className="flex items-center justify-end space-x-2">
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button 
                                        variant="outline" 
                                        size="icon" 
                                        onClick={() => handleViewJobDetails(job.id)}
                                    >
                                        <Eye className="h-4 w-4" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>View Job Details</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button variant="outline" size="icon" onClick={() => handleDeleteClick(job)}>
                                        <span className="sr-only">Delete</span>
                                        <Trash2 className="h-4 w-4" />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    <p>Delete Job</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>
                    </div>
                );
            default:
                return job[columnId];
        }
    };

    if (loading) {
        return (
            <AnimatedPage>
                <Skeleton className="h-10 w-32 mb-4" />
                <Card>
                    <CardHeader>
                        <Skeleton className="h-8 w-1/2" />
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            {[...Array(5)].map((_, index) => (
                                <div key={index} className="flex items-center">
                                    <Skeleton className="h-5 w-5 mr-2" />
                                    <Skeleton className="h-4 w-1/2" />
                                </div>
                            ))}
                        </div>
                        <Skeleton className="h-1 w-full my-6" />
                        <Skeleton className="h-8 w-1/4 mb-4" />
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    {[...Array(4)].map((_, index) => (
                                        <TableHead key={index}>
                                            <Skeleton className="h-4 w-full" />
                                        </TableHead>
                                    ))}
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {[...Array(3)].map((_, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {[...Array(4)].map((_, cellIndex) => (
                                            <TableCell key={cellIndex}>
                                                <Skeleton className="h-4 w-full" />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </AnimatedPage>
        );
    }

    if (error) return <ErrorAlert message={error} />;
    if (!user) return <div className="text-center mt-8">User not found</div>;

    return (
        <AnimatedPage>
            <div className="space-y-6">
                <div className="flex items-center justify-between">
                    <Button onClick={() => navigate('/admin/users')} variant="outline" className="flex items-center">
                        <ArrowLeft className="h-5 w-5 mr-2" />
                        Back to Users
                    </Button>
                    <Button
                        onClick={handleToggleUserStatus}
                        disabled={disablingUser}
                        variant={user?.disabled ? "default" : "destructive"}
                    >
                        {disablingUser ? (
                            <>
                                <LoadingSpinner ariaLabel="Toggling user status" className="mr-2 h-4 w-4" />
                                {user?.disabled ? 'Enabling...' : 'Disabling...'}
                            </>
                        ) : user?.disabled ? (
                            'Enable User'
                        ) : (
                            'Disable User'
                        )}
                    </Button>
                </div>

                <div className="grid gap-6 md:grid-cols-2">
                    {/* Basic Information Card */}
                    <Card>
                        <CardHeader className="pb-3">
                            <div className="flex items-center space-x-4">
                                <div className="bg-primary/10 p-3 rounded-full">
                                    <User className="h-6 w-6 text-primary" />
                                </div>
                                <div>
                                    <CardTitle>Basic Information</CardTitle>
                                    <p className="text-sm text-muted-foreground">User's personal and contact details</p>
                                </div>
                            </div>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            <div className="grid gap-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <User className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Full Name</p>
                                            <p className="text-sm text-muted-foreground">{user?.firstName || 'N/A'} {user?.lastName || ''}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <Mail className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Email</p>
                                            <p className="text-sm text-muted-foreground">{user?.email || 'N/A'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <Calendar className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Joined Date</p>
                                            <p className="text-sm text-muted-foreground">
                                                {user?.createdAt && user.createdAt.toDate ? new Date(user.createdAt.toDate()).toLocaleDateString() : 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Company Information Card */}
                    <Card>
                        <CardHeader className="pb-3">
                            <div className="flex items-center space-x-4">
                                <div className="bg-primary/10 p-3 rounded-full">
                                    <Building className="h-6 w-6 text-primary" />
                                </div>
                                <div>
                                    <CardTitle>Company Information</CardTitle>
                                    <p className="text-sm text-muted-foreground">Company details and role</p>
                                </div>
                            </div>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            <div className="grid gap-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <Building className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Company Name</p>
                                            <p className="text-sm text-muted-foreground">{user?.companyName || 'Not specified'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <Briefcase className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Job Title</p>
                                            <p className="text-sm text-muted-foreground">{user?.jobTitle || 'Not specified'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <Users className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Company Size</p>
                                            <p className="text-sm text-muted-foreground">{user?.companySize || 'Not specified'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Account Status Card */}
                    <Card>
                        <CardHeader className="pb-3">
                            <div className="flex items-center space-x-4">
                                <div className="bg-primary/10 p-3 rounded-full">
                                    <Shield className="h-6 w-6 text-primary" />
                                </div>
                                <div>
                                    <CardTitle>Account Status</CardTitle>
                                    <p className="text-sm text-muted-foreground">Account security and status information</p>
                                </div>
                            </div>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            <div className="grid gap-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <Activity className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Account Status</p>
                                            <Badge variant={user?.disabled ? "secondary" : "success"}>
                                                {user?.disabled ? 'Disabled' : 'Active'}
                                            </Badge>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <Shield className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Two-Factor Authentication</p>
                                            <Badge variant={user?.twoFactorEnabled ? "success" : "secondary"}>
                                                {user?.twoFactorEnabled ? 'Enabled' : 'Disabled'}
                                            </Badge>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <CheckCircle className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Email Verification</p>
                                            <Badge variant={user?.emailVerified ? "success" : "secondary"}>
                                                {user?.emailVerified ? 'Verified' : 'Not Verified'}
                                            </Badge>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>

                    {/* Additional Settings Card */}
                    <Card>
                        <CardHeader className="pb-3">
                            <div className="flex items-center space-x-4">
                                <div className="bg-primary/10 p-3 rounded-full">
                                    <Globe className="h-6 w-6 text-primary" />
                                </div>
                                <div>
                                    <CardTitle>Additional Settings</CardTitle>
                                    <p className="text-sm text-muted-foreground">Website and communication preferences</p>
                                </div>
                            </div>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            <div className="grid gap-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <Globe className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Company Website</p>
                                            <p className="text-sm text-muted-foreground">{user?.companyWebsite || 'Not specified'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <LinkIcon className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Career Page URL</p>
                                            <p className="text-sm text-muted-foreground">{user?.careerPageUrl || 'Not generated'}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <Send className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Automated Emails</p>
                                            <div className="flex items-center space-x-2">
                                                <Badge variant={user?.emailSettings?.sendConfirmationEmail ? "success" : "secondary"}>
                                                    {user?.emailSettings?.sendConfirmationEmail ? 'Enabled' : 'Disabled'}
                                                </Badge>
                                                {user?.emailSettings?.sendConfirmationEmail && (
                                                    <TooltipProvider>
                                                        <Tooltip>
                                                            <TooltipTrigger asChild>
                                                                <Button variant="ghost" size="icon" className="h-8 w-8" onClick={handlePreviewEmail}>
                                                                    <Eye className="h-4 w-4" />
                                                                </Button>
                                                            </TooltipTrigger>
                                                            <TooltipContent>
                                                                <p>Preview Email Template</p>
                                                            </TooltipContent>
                                                        </Tooltip>
                                                    </TooltipProvider>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-4">
                                        <Clock className="h-5 w-5 text-muted-foreground" />
                                        <div>
                                            <p className="text-sm font-medium">Last Activity</p>
                                            <p className="text-sm text-muted-foreground">
                                                {user?.lastActivity ? new Date(user.lastActivity).toLocaleString() : 'N/A'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* Jobs Section */}
                <Card>
                    <CardHeader>
                        <CardTitle>Published Jobs</CardTitle>
                    </CardHeader>
                    <CardContent>
                        {jobs.length === 0 ? (
                            <div className="text-center py-6 text-muted-foreground">
                                This user has not published any jobs yet.
                            </div>
                        ) : (
                            <>
                                <Table>
                                    <TableHeader>
                                        <TableRow>
                                            <TableHead className="w-[50px]">
                                                <Checkbox
                                                    checked={selectedJobs.size === jobs.length}
                                                    onCheckedChange={(checked) => {
                                                        setSelectedJobs(checked ? new Set(jobs.map(job => job.id)) : new Set());
                                                    }}
                                                />
                                            </TableHead>
                                            {columns.slice(1).map((column) => (
                                                <TableHead key={column.id} className={column.id === 'actions' ? 'text-right' : ''}>
                                                    {column.name}
                                                </TableHead>
                                            ))}
                                        </TableRow>
                                    </TableHeader>
                                    <TableBody>
                                        {jobs.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((job) => (
                                            <TableRow key={job.id}>
                                                {columns.map((column) => (
                                                    <TableCell key={`${job.id}-${column.id}`} className={column.id === 'actions' ? 'text-right' : ''}>
                                                        {renderCell(job, column.id)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <div className="flex items-center justify-between mt-4">
                                    <div className="flex-1 text-sm text-muted-foreground">
                                        {selectedJobs.size} of {jobs.length} row(s) selected.
                                    </div>
                                    <div className="flex items-center space-x-6 lg:space-x-8">
                                        <div className="flex items-center space-x-2">
                                            <p className="text-sm font-medium">Rows per page</p>
                                            <Select
                                                value={`${rowsPerPage}`}
                                                onValueChange={(value) => setRowsPerPage(Number(value))}
                                            >
                                                <SelectTrigger className="h-8 w-[70px]">
                                                    <SelectValue placeholder={rowsPerPage} />
                                                </SelectTrigger>
                                                <SelectContent side="top">
                                                    {[10, 20, 30, 40, 50].map((pageSize) => (
                                                        <SelectItem key={pageSize} value={`${pageSize}`}>
                                                            {pageSize}
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                        </div>
                                        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
                                            Page {page} of {Math.ceil(jobs.length / rowsPerPage)}
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <Button
                                                variant="outline"
                                                className="hidden h-8 w-8 p-0 lg:flex"
                                                onClick={() => setPage(1)}
                                                disabled={page === 1}
                                            >
                                                <span className="sr-only">Go to first page</span>
                                                <ChevronLeft className="h-4 w-4" />
                                                <ChevronLeft className="h-4 w-4" />
                                            </Button>
                                            <Button
                                                variant="outline"
                                                className="h-8 w-8 p-0"
                                                onClick={() => setPage(page - 1)}
                                                disabled={page === 1}
                                            >
                                                <span className="sr-only">Go to previous page</span>
                                                <ChevronLeft className="h-4 w-4" />
                                            </Button>
                                            <Button
                                                variant="outline"
                                                className="h-8 w-8 p-0"
                                                onClick={() => setPage(page + 1)}
                                                disabled={page === Math.ceil(jobs.length / rowsPerPage)}
                                            >
                                                <span className="sr-only">Go to next page</span>
                                                <ChevronRight className="h-4 w-4" />
                                            </Button>
                                            <Button
                                                variant="outline"
                                                className="hidden h-8 w-8 p-0 lg:flex"
                                                onClick={() => setPage(Math.ceil(jobs.length / rowsPerPage))}
                                                disabled={page === Math.ceil(jobs.length / rowsPerPage)}
                                            >
                                                <span className="sr-only">Go to last page</span>
                                                <ChevronRight className="h-4 w-4" />
                                                <ChevronRight className="h-4 w-4" />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                {selectedJobs.size > 0 && (
                                    <div className="mt-4">
                                        <Button variant="destructive" onClick={() => setBulkDeleteModalOpen(true)}>
                                            Delete Selected ({selectedJobs.size})
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                    </CardContent>
                </Card>
            </div>

            <Dialog open={jobDeleteModalOpen} onOpenChange={setJobDeleteModalOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Job</DialogTitle>
                        <DialogDescription>
                            Are you sure you want to delete the job "{jobToDelete?.title}"? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setJobDeleteModalOpen(false)}>Cancel</Button>
                        <Button variant="destructive" onClick={handleJobDelete} disabled={isDeleting}>
                            {isDeleting ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Deleting...
                                </>
                            ) : (
                                'Delete'
                            )}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <Dialog open={bulkDeleteModalOpen} onOpenChange={setBulkDeleteModalOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Jobs</DialogTitle>
                        <DialogDescription>
                            Are you sure you want to delete {selectedJobs.size} jobs? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setBulkDeleteModalOpen(false)}>Cancel</Button>
                        <Button variant="destructive" onClick={handleBulkDelete} disabled={isDeleting}>
                            {isDeleting ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Deleting...
                                </>
                            ) : (
                                'Delete'
                            )}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <Dialog open={emailPreviewOpen} onOpenChange={setEmailPreviewOpen}>
                <DialogContent className="sm:max-w-[725px] max-h-[80vh] overflow-y-auto">
                    <DialogHeader>
                        <DialogTitle>Email Preview</DialogTitle>
                        <DialogDescription>
                            This is how the automated email will appear to candidates
                        </DialogDescription>
                    </DialogHeader>
                    <div className="mt-4 border rounded-md p-4">
                        <div dangerouslySetInnerHTML={{ __html: emailPreviewHtml }} />
                    </div>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setEmailPreviewOpen(false)}>Close</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <Dialog open={jobDetailsOpen} onOpenChange={setJobDetailsOpen}>
                <DialogContent className="max-w-7xl max-h-[90vh] overflow-y-auto">
                    {selectedJobId && (
                        <AdminJobDetails jobId={selectedJobId} isDialog={true} onClose={() => setJobDetailsOpen(false)} />
                    )}
                </DialogContent>
            </Dialog>
        </AnimatedPage>
    );
};

export default withAdminAuth(AdminUserDetails);