import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { withAdminAuth } from '../../../contexts/adminAuthContext';
import { getJobDetails, deleteJob } from '../../../services/adminService';
import { handleError } from '../../../utils/errorHandler';
import { AnimatedPage } from '../../../utils/animations';
import ErrorAlert from '../../common/ErrorAlert';
import { Building, MapPin, DollarSign, Calendar, Clock, GraduationCap, CheckCircle, ArrowLeft, Search, Loader2, Briefcase, Users, Globe, Share2 } from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Skeleton } from "@/components/ui/skeleton";
import { JOB_BOARDS } from '../../../constants/appConstants';

const AdminJobDetails = ({ jobId, isDialog = false, onClose }) => {
    const [job, setJob] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const params = useParams();
    const navigate = useNavigate();

    const id = jobId || params.id;

    useEffect(() => {
        fetchJobDetails();
    }, [id]);

    const fetchJobDetails = async () => {
        try {
            const jobData = await getJobDetails(id);
            setJob(jobData);
            setLoading(false);
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        setIsDeleting(true);
        try {
            await deleteJob(id);
            if (isDialog && onClose) {
                onClose();
            } else {
                navigate('/admin/jobs');
            }
        } catch (err) {
            const { message } = handleError(err);
            setError(message);
        } finally {
            setIsDeleting(false);
        }
    };

    const getPublishedJobBoards = (job) => {
        return Object.entries(JOB_BOARDS)
            .filter(([key, value]) => job[`publishTo${value}`])
            .map(([_, value]) => value)
            .join(', ') || 'Not published to any job board';
    };

    const content = job ? (
        <div className="space-y-6">
            {!isDialog && (
                <div className="flex items-center justify-between">
                    <Button onClick={() => navigate('/admin/jobs')} variant="outline" className="flex items-center">
                        <ArrowLeft className="h-5 w-5 mr-2" />
                        Back to Jobs
                    </Button>
                    <Button
                        variant="destructive"
                        onClick={() => setDeleteModalOpen(true)}
                    >
                        Delete Job
                    </Button>
                </div>
            )}

            <div className="grid gap-6 md:grid-cols-2">
                {/* Basic Job Information */}
                <Card>
                    <CardHeader className="pb-3">
                        <div className="flex items-center space-x-4">
                            <div className="bg-primary/10 p-3 rounded-full">
                                <Briefcase className="h-6 w-6 text-primary" />
                            </div>
                            <div>
                                <CardTitle>Job Details</CardTitle>
                                <p className="text-sm text-muted-foreground">Basic job information</p>
                            </div>
                        </div>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <div className="grid gap-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                    <Briefcase className="h-5 w-5 text-muted-foreground" />
                                    <div>
                                        <p className="text-sm font-medium">Job Title</p>
                                        <p className="text-sm text-muted-foreground">{job.title}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                    <Building className="h-5 w-5 text-muted-foreground" />
                                    <div>
                                        <p className="text-sm font-medium">Company</p>
                                        <p className="text-sm text-muted-foreground">{job.company}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                    <MapPin className="h-5 w-5 text-muted-foreground" />
                                    <div>
                                        <p className="text-sm font-medium">Location</p>
                                        <p className="text-sm text-muted-foreground">{job.city}, {job.country}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {/* Employment Details */}
                <Card>
                    <CardHeader className="pb-3">
                        <div className="flex items-center space-x-4">
                            <div className="bg-primary/10 p-3 rounded-full">
                                <Users className="h-6 w-6 text-primary" />
                            </div>
                            <div>
                                <CardTitle>Employment Details</CardTitle>
                                <p className="text-sm text-muted-foreground">Job type and requirements</p>
                            </div>
                        </div>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <div className="grid gap-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                    <Clock className="h-5 w-5 text-muted-foreground" />
                                    <div>
                                        <p className="text-sm font-medium">Work Schedule</p>
                                        <p className="text-sm text-muted-foreground">{job.jobType} | {job.workTime}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                    <DollarSign className="h-5 w-5 text-muted-foreground" />
                                    <div>
                                        <p className="text-sm font-medium">Salary Range</p>
                                        <p className="text-sm text-muted-foreground">{job.salaryMin} - {job.salaryMax} {job.currency}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                    <GraduationCap className="h-5 w-5 text-muted-foreground" />
                                    <div>
                                        <p className="text-sm font-medium">Experience Level</p>
                                        <p className="text-sm text-muted-foreground">{job.experienceLevel}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {/* Status and Publication */}
                <Card>
                    <CardHeader className="pb-3">
                        <div className="flex items-center space-x-4">
                            <div className="bg-primary/10 p-3 rounded-full">
                                <Globe className="h-6 w-6 text-primary" />
                            </div>
                            <div>
                                <CardTitle>Status & Publication</CardTitle>
                                <p className="text-sm text-muted-foreground">Job status and distribution</p>
                            </div>
                        </div>
                    </CardHeader>
                    <CardContent className="space-y-4">
                        <div className="grid gap-4">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                    <CheckCircle className="h-5 w-5 text-muted-foreground" />
                                    <div>
                                        <p className="text-sm font-medium">Job Status</p>
                                        <Badge variant={job.status === 'Active' ? "success" : "secondary"}>
                                            {job.status}
                                        </Badge>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                    <Share2 className="h-5 w-5 text-muted-foreground" />
                                    <div>
                                        <p className="text-sm font-medium">Published Job Boards</p>
                                        <p className="text-sm text-muted-foreground">{getPublishedJobBoards(job)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                    <Calendar className="h-5 w-5 text-muted-foreground" />
                                    <div>
                                        <p className="text-sm font-medium">Start Date</p>
                                        <p className="text-sm text-muted-foreground">{job.startDate}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {/* Required Skills */}
                <Card>
                    <CardHeader className="pb-3">
                        <div className="flex items-center space-x-4">
                            <div className="bg-primary/10 p-3 rounded-full">
                                <GraduationCap className="h-6 w-6 text-primary" />
                            </div>
                            <div>
                                <CardTitle>Required Skills</CardTitle>
                                <p className="text-sm text-muted-foreground">Skills and qualifications</p>
                            </div>
                        </div>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-2">
                            {Array.isArray(job.skills) ? (
                                job.skills.map((skill, index) => (
                                    <Badge key={index} variant="secondary" className="mr-2 mb-2">
                                        {skill}
                                    </Badge>
                                ))
                            ) : (
                                <Badge variant="secondary">{job.skills}</Badge>
                            )}
                        </div>
                    </CardContent>
                </Card>
            </div>

            {/* Job Description */}
            <Card>
                <CardHeader className="pb-3">
                    <div className="flex items-center space-x-4">
                        <div className="bg-primary/10 p-3 rounded-full">
                            <Search className="h-6 w-6 text-primary" />
                        </div>
                        <div>
                            <CardTitle>Job Description</CardTitle>
                            <p className="text-sm text-muted-foreground">Detailed job information</p>
                        </div>
                    </div>
                </CardHeader>
                <CardContent>
                    <div className="prose prose-sm max-w-none dark:prose-invert">
                        <ReactQuill
                            value={job.description}
                            readOnly={true}
                            theme="bubble"
                            modules={{ toolbar: false }}
                        />
                    </div>
                </CardContent>
            </Card>

            {isDialog && (
                <div className="flex justify-end space-x-2">
                    <Button variant="outline" onClick={onClose}>
                        Close
                    </Button>
                    <Button
                        variant="destructive"
                        onClick={() => setDeleteModalOpen(true)}
                    >
                        Delete Job
                    </Button>
                </div>
            )}
        </div>
    ) : null;

    if (loading) {
        return (
            <AnimatedPage>
                <div className="space-y-6">
                    {!isDialog && <Skeleton className="h-10 w-32" />}
                    <div className="grid gap-6 md:grid-cols-2">
                        {[...Array(3)].map((_, index) => (
                            <Card key={index}>
                                <CardHeader className="pb-3">
                                    <div className="flex items-center space-x-4">
                                        <Skeleton className="h-12 w-12 rounded-full" />
                                        <div className="space-y-2">
                                            <Skeleton className="h-5 w-40" />
                                            <Skeleton className="h-4 w-60" />
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardContent>
                                    <div className="space-y-4">
                                        {[...Array(4)].map((_, i) => (
                                            <div key={i} className="flex items-center space-x-4">
                                                <Skeleton className="h-5 w-5" />
                                                <div className="space-y-1">
                                                    <Skeleton className="h-4 w-24" />
                                                    <Skeleton className="h-3 w-40" />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </div>
                </div>
            </AnimatedPage>
        );
    }

    if (error) return <ErrorAlert message={error} />;
    if (!job) return <div className="text-center mt-8">Job not found</div>;

    return (
        <>
            {isDialog ? content : <AnimatedPage>{content}</AnimatedPage>}

            <Dialog open={deleteModalOpen} onOpenChange={setDeleteModalOpen}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Job</DialogTitle>
                        <DialogDescription>
                            Are you sure you want to delete this job? This action cannot be undone.
                        </DialogDescription>
                    </DialogHeader>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
                        <Button variant="destructive" onClick={handleDelete} disabled={isDeleting}>
                            {isDeleting ? (
                                <>
                                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                    Deleting...
                                </>
                            ) : (
                                'Delete'
                            )}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default withAdminAuth(AdminJobDetails);